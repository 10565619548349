import {
  actionCreator,
  checkHttpStatus,
  checkAuthTokenExpiry,
  publicHeaders,
  createQueryParams,
} from "../../../actions";
import { logout } from "../../login/actions";
import {
  ASSIGNMENT_READ_ONE_URL,
  PAYOUT_READ_MANY_URL,
  payoutActionTypes,
} from "../constants";

export const getRecentDeposits = (query) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(payoutActionTypes.getRecentDeposits.REQUEST));
    query = {
      sortKey: "created",
      sortValue: -1,
      ...query,
    };
    const queryParams = createQueryParams(query);

    fetch(`${PAYOUT_READ_MANY_URL}${queryParams}`, {
      method: "GET",
      headers: publicHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(payoutActionTypes.getRecentDeposits.SUCCESS, response),
        );
      })
      .catch((error) => {
        dispatch(actionCreator(payoutActionTypes.getRecentDeposits.FAILURE));
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};
export const getAssignmentOverview = (query) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(payoutActionTypes.getAssignmentOverview.REQUEST));
    query = {
      calculate: "availableBalance availableBalanceInStripe",
      ...query,
    };
    const queryParams = createQueryParams(query);

    // * TODO: this hits the user read one API, not assignment. idk why the action, url const, store key, etc are all named assignment
    fetch(`${ASSIGNMENT_READ_ONE_URL}${queryParams}`, {
      method: "GET",
      headers: publicHeaders,
      credentials: "include",
    })
      .then(checkHttpStatus)
      .then((response) => checkAuthTokenExpiry(response))
      .then((response) => {
        dispatch(
          actionCreator(
            payoutActionTypes.getAssignmentOverview.SUCCESS,
            response?.result,
          ),
        );
      })
      .catch((error) => {
        dispatch(
          actionCreator(payoutActionTypes.getAssignmentOverview.FAILURE),
        );
        if (error?.statusCode === 401) {
          dispatch(logout(error));
        }
      });
  };
};
export const clearErrorMessages = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(payoutActionTypes.clearErrorMessages.SUCCESS));
  };
};
